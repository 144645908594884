<template>
<div>
    <div class="kt-container kt-container--fluid  kt-grid__item kt-grid__item--fluid">
        <div class="row">
            <div class="col-12">
                <div class="kt-portlet kt-portlet--mobile kt-portlet">
                    <div class="kt-portlet__head">
                        <div class="kt-portlet__head-label">
                            <h3 class="kt-portlet__head-title">
                                Create Local User
                            </h3>
                        </div>
                    </div>

                    <div class="kt-portlet__body">
                        <div class="kt-section mb-0 mt-3">
                            <div class="kt-section__title">
                                User Details
                            </div>
                        </div>
                        <div class="form-group validated row pl-2 pr-2">
                            <div class="p-3 pr-4 col-lg-6">
                                Role
                                <select
                                    v-model="newUser.role"
                                    class="form-control"
                                    @change="changeRole($event)"
                                >
                                    <option
                                        :value="'Super Admin'"
                                    >
                                        Super Admin
                                    </option>
                                    <option
                                        :value="'School Admin'"
                                    >
                                        School Admin
                                    </option>
                                    <option
                                        :value="'Staff'"
                                    >
                                        School Staff
                                    </option>
                                </select>
                            </div>
                            <div class="p-3 pr-4 col-lg-6">
                                School
                                <select
                                    v-model="newUser.school"
                                    type="text"
                                    class="form-control"
                                    :class="isValidSchool ? '' : 'is-invalid'"
                                    :disabled="!['School Admin', 'Staff',].includes(newUser.role)"
                                >
                                    <option
                                        v-for="(school, idx) in schools"
                                        :key="`school_${school.schoolId}_${idx}`"
                                        :value="school"
                                    >
                                        {{ school.schoolName }}
                                    </option>
                                </select>
                                <div
                                    v-if="!isValidSchool"
                                    class="invalid-danger-feedback"
                                >
                                    Please select a School
                                </div>
                            </div>
                        </div>

                        <div class="form-group validated row pl-2 pr-2">
                            <div class="p-3 pr-4 col-lg-4">
                                Email Address:

                                <input
                                    v-model="newUser.emailAddress"
                                    :placeholder="['Super Admin'].includes(newUser.role) ? 'e.g. barack.obama@syncgrades.com' : 'e.g. barack.obama@email.com'"
                                    type="email"
                                    :class="isValidEmail ? '' : 'is-invalid'"
                                    class="form-control"
                                >
                                <div
                                    v-if="!isValidEmail"
                                    class="invalid-danger-feedback"
                                >
                                    Please enter a valid email address
                                    <template v-if="['Super Admin'].includes(newUser.role)">
                                        ending in <code>@syncgrades.com</code>
                                    </template>
                                </div>
                            </div>
                            <div class="p-3 pr-4 col-lg-4">
                                First Name:
                                <input
                                    v-model="newUser.firstName"
                                    type="text"
                                    class="form-control"
                                    :class="isFirstNameValid ? '' : 'is-invalid'"
                                >
                                <div
                                    v-if="!isFirstNameValid"
                                    class="invalid-feedback"
                                >
                                    Please enter a valid first name.
                                </div>
                            </div>

                            <div class="p-3 pr-4 col-lg-4">
                                Last Name:
                                <input
                                    v-model="newUser.lastName"
                                    type="text"
                                    class="form-control"
                                    :class="isLastNameValid ? '' : 'is-invalid'"
                                >
                                <div
                                    v-if="!isLastNameValid"
                                    class="invalid-feedback"
                                >
                                    Please enter a valid last name.
                                </div>
                            </div>
                        </div>

                        <div class="form-group validated row pl-2 pr-2">
                            <div class="p-3 pr-4 col-lg-4 float-right">
                                Temporary Password
                                <div class="input-group">
                                    <input
                                        v-model="newUser.password"
                                        type="text"
                                        class="form-control"
                                    >
                                    <div class="input-group-append">
                                        <button
                                            class="btn btn-outline-primary"
                                            @click.stop.prevent="getPassword"
                                        >
                                            <i
                                                v-if="newUser.password"
                                                class="fa fa-redo"
                                            />
                                            <span v-else>
                                                Show
                                            </span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="p-4 mb-2">
                            <div class="kt-form__actions">
                                <button
                                    type="button"
                                    class="btn btn-bold btn-primary ml-3 pull-right"
                                    :disabled="!isUserValid"
                                    :class="{
                                        'kt-spinner kt-spinner--sm kt-spinner--light': saving,
                                    }"
                                    @click.stop.prevent="createUser"
                                >
                                    <i
                                        v-if="!saving"
                                        class="fa fa-save"
                                    />
                                    <template v-if="saving">
                                        Creating User...
                                    </template>
                                    <template v-else>
                                        Create User
                                    </template>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script lang="ts">
import { mapState } from 'vuex';
import { v4 as uuidv4 } from 'uuid';
import * as network from '../../network';

export default {
    name: 'CreateLocalUser',
    components: {

    },
    data() {
        return {
            newUser: {
                role: null,
                emailAddress: null,
                firstName: null,
                lastName: null,
                school: null,
                password: null,
            },
            saving: false,
        };
    },
    computed: {
        ...mapState({
            user: (state) => state.user,
            schools: (state) => state.database.schools,
        }),
        isValidRole() {
            const { newUser } = this;
            return !!newUser.role;
        },
        isValidSchool() {
            const { newUser } = this;
            if (newUser.role === 'Super Admin') {
                return true;
            }
            return !!newUser.school;
        },
        isValidEmail() {
            const v = this;
            const { emailAddress, role } = v.newUser;
            if (!emailAddress) {
                return false;
            }
            if (['Super Admin'].includes(role)) {
                if (emailAddress.split('@')[1].toLowerCase() !== 'syncgrades.com') return false;
            }

            return v.isValidEmailAddress(emailAddress);
        },
        isFirstNameValid() {
            return (this.newUser.firstName || '').length > 0;
        },
        isLastNameValid() {
            return (this.newUser.lastName || '').length > 0;
        },
        isUserValid() {
            const v = this;
            return v.isValidRole && v.isValidSchool && v.isValidEmail && v.isFirstNameValid
                && v.isLastNameValid && v.newUser.password;
        },
    },
    methods: {
        changeRole(e) {
            if (['Super Admin'].includes(e.target.value)) this.newUser.school = null;
        },
        getPassword() {
            const v = this;
            const rand = uuidv4().replace(/-/g, '');
            v.newUser.password = rand.substring(0, 14);
        },
        createUser() {
            const v = this;
            const { newUser, isUserValid } = v;
            if (v.saving || !isUserValid) return;
            v.saving = true;

            const isSuperAdmin = ['Super Admin'].includes(newUser.role);
            const term = isSuperAdmin ? null : newUser.school.schoolTerms.find((t) => t.current);
            if (!term && !isSuperAdmin) return v.showError('Cant find term for school');

            const schoolTermId = isSuperAdmin ? null : term.schoolTermId;
            const schoolId = isSuperAdmin ? null : newUser.school.schoolId;
            const params = {
                body: {
                    newUser: {
                        emailAddress: newUser.emailAddress,
                        firstName: newUser.firstName,
                        lastName: newUser.lastName,
                        role: newUser.role,
                        schoolId: isSuperAdmin ? null : schoolId,
                        schoolTermId: isSuperAdmin ? null : schoolTermId,
                        password: newUser.password,
                    },
                },
            };

            network.admin.createLocalUser(params, (err, res) => {
                v.saving = false;
                if (err) return v.showError('Error creating Local User');
                if (res.created) {
                    v.newUser = {
                        role: null,
                        emailAddress: null,
                        firstName: null,
                        lastName: null,
                        school: null,
                        password: null,
                    };
                    return v.showNotification('User Created');
                }
            });
        },
    },
};
</script>
