var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      {
        staticClass:
          "kt-container kt-container--fluid kt-grid__item kt-grid__item--fluid",
      },
      [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-12" }, [
            _c(
              "div",
              { staticClass: "kt-portlet kt-portlet--mobile kt-portlet" },
              [
                _vm._m(0),
                _c("div", { staticClass: "kt-portlet__body" }, [
                  _vm._m(1),
                  _c(
                    "div",
                    { staticClass: "form-group validated row pl-2 pr-2" },
                    [
                      _c("div", { staticClass: "p-3 pr-4 col-lg-6" }, [
                        _vm._v(" Role "),
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.newUser.role,
                                expression: "newUser.role",
                              },
                            ],
                            staticClass: "form-control",
                            on: {
                              change: [
                                function ($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function (o) {
                                      return o.selected
                                    })
                                    .map(function (o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.$set(
                                    _vm.newUser,
                                    "role",
                                    $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  )
                                },
                                function ($event) {
                                  return _vm.changeRole($event)
                                },
                              ],
                            },
                          },
                          [
                            _c(
                              "option",
                              { domProps: { value: "Super Admin" } },
                              [_vm._v(" Super Admin ")]
                            ),
                            _c(
                              "option",
                              { domProps: { value: "School Admin" } },
                              [_vm._v(" School Admin ")]
                            ),
                            _c("option", { domProps: { value: "Staff" } }, [
                              _vm._v(" School Staff "),
                            ]),
                          ]
                        ),
                      ]),
                      _c("div", { staticClass: "p-3 pr-4 col-lg-6" }, [
                        _vm._v(" School "),
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.newUser.school,
                                expression: "newUser.school",
                              },
                            ],
                            staticClass: "form-control",
                            class: _vm.isValidSchool ? "" : "is-invalid",
                            attrs: {
                              type: "text",
                              disabled: !["School Admin", "Staff"].includes(
                                _vm.newUser.role
                              ),
                            },
                            on: {
                              change: function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  _vm.newUser,
                                  "school",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              },
                            },
                          },
                          _vm._l(_vm.schools, function (school, idx) {
                            return _c(
                              "option",
                              {
                                key: `school_${school.schoolId}_${idx}`,
                                domProps: { value: school },
                              },
                              [_vm._v(" " + _vm._s(school.schoolName) + " ")]
                            )
                          }),
                          0
                        ),
                        !_vm.isValidSchool
                          ? _c(
                              "div",
                              { staticClass: "invalid-danger-feedback" },
                              [_vm._v(" Please select a School ")]
                            )
                          : _vm._e(),
                      ]),
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "form-group validated row pl-2 pr-2" },
                    [
                      _c("div", { staticClass: "p-3 pr-4 col-lg-4" }, [
                        _vm._v(" Email Address: "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.newUser.emailAddress,
                              expression: "newUser.emailAddress",
                            },
                          ],
                          staticClass: "form-control",
                          class: _vm.isValidEmail ? "" : "is-invalid",
                          attrs: {
                            placeholder: ["Super Admin"].includes(
                              _vm.newUser.role
                            )
                              ? "e.g. barack.obama@syncgrades.com"
                              : "e.g. barack.obama@email.com",
                            type: "email",
                          },
                          domProps: { value: _vm.newUser.emailAddress },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.newUser,
                                "emailAddress",
                                $event.target.value
                              )
                            },
                          },
                        }),
                        !_vm.isValidEmail
                          ? _c(
                              "div",
                              { staticClass: "invalid-danger-feedback" },
                              [
                                _vm._v(" Please enter a valid email address "),
                                ["Super Admin"].includes(_vm.newUser.role)
                                  ? [
                                      _vm._v(" ending in "),
                                      _c("code", [_vm._v("@syncgrades.com")]),
                                    ]
                                  : _vm._e(),
                              ],
                              2
                            )
                          : _vm._e(),
                      ]),
                      _c("div", { staticClass: "p-3 pr-4 col-lg-4" }, [
                        _vm._v(" First Name: "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.newUser.firstName,
                              expression: "newUser.firstName",
                            },
                          ],
                          staticClass: "form-control",
                          class: _vm.isFirstNameValid ? "" : "is-invalid",
                          attrs: { type: "text" },
                          domProps: { value: _vm.newUser.firstName },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.newUser,
                                "firstName",
                                $event.target.value
                              )
                            },
                          },
                        }),
                        !_vm.isFirstNameValid
                          ? _c("div", { staticClass: "invalid-feedback" }, [
                              _vm._v(" Please enter a valid first name. "),
                            ])
                          : _vm._e(),
                      ]),
                      _c("div", { staticClass: "p-3 pr-4 col-lg-4" }, [
                        _vm._v(" Last Name: "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.newUser.lastName,
                              expression: "newUser.lastName",
                            },
                          ],
                          staticClass: "form-control",
                          class: _vm.isLastNameValid ? "" : "is-invalid",
                          attrs: { type: "text" },
                          domProps: { value: _vm.newUser.lastName },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.newUser,
                                "lastName",
                                $event.target.value
                              )
                            },
                          },
                        }),
                        !_vm.isLastNameValid
                          ? _c("div", { staticClass: "invalid-feedback" }, [
                              _vm._v(" Please enter a valid last name. "),
                            ])
                          : _vm._e(),
                      ]),
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "form-group validated row pl-2 pr-2" },
                    [
                      _c(
                        "div",
                        { staticClass: "p-3 pr-4 col-lg-4 float-right" },
                        [
                          _vm._v(" Temporary Password "),
                          _c("div", { staticClass: "input-group" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.newUser.password,
                                  expression: "newUser.password",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: { type: "text" },
                              domProps: { value: _vm.newUser.password },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.newUser,
                                    "password",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                            _c("div", { staticClass: "input-group-append" }, [
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-outline-primary",
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      $event.preventDefault()
                                      return _vm.getPassword.apply(
                                        null,
                                        arguments
                                      )
                                    },
                                  },
                                },
                                [
                                  _vm.newUser.password
                                    ? _c("i", { staticClass: "fa fa-redo" })
                                    : _c("span", [_vm._v(" Show ")]),
                                ]
                              ),
                            ]),
                          ]),
                        ]
                      ),
                    ]
                  ),
                  _c("div", { staticClass: "p-4 mb-2" }, [
                    _c("div", { staticClass: "kt-form__actions" }, [
                      _c(
                        "button",
                        {
                          staticClass:
                            "btn btn-bold btn-primary ml-3 pull-right",
                          class: {
                            "kt-spinner kt-spinner--sm kt-spinner--light":
                              _vm.saving,
                          },
                          attrs: { type: "button", disabled: !_vm.isUserValid },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              $event.preventDefault()
                              return _vm.createUser.apply(null, arguments)
                            },
                          },
                        },
                        [
                          !_vm.saving
                            ? _c("i", { staticClass: "fa fa-save" })
                            : _vm._e(),
                          _vm.saving
                            ? [_vm._v(" Creating User... ")]
                            : [_vm._v(" Create User ")],
                        ],
                        2
                      ),
                    ]),
                  ]),
                ]),
              ]
            ),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "kt-portlet__head" }, [
      _c("div", { staticClass: "kt-portlet__head-label" }, [
        _c("h3", { staticClass: "kt-portlet__head-title" }, [
          _vm._v(" Create Local User "),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "kt-section mb-0 mt-3" }, [
      _c("div", { staticClass: "kt-section__title" }, [
        _vm._v(" User Details "),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }